<template>
    <v-hover v-slot:default="{hover}">
        <v-card class="card off mx-2 mb-1" :class="{ 'card_hover' : hover}"
                :elevation="hover ? 4 : 2" style="cursor: pointer;">
            <v-card-text class="card-state pa-1" :class="state">
                <!--HEADER-->
                <v-row no-gutters justify="center">
                    <v-col cols="12" class="pb-0" :md="grid.md">
                        <span class="font-weight-bold">
                            {{ item.date }} • <span class="gold--text">{{ item.id }}</span>
                        </span>
                        <span class="tag-status state-green white--text rounded px-3 ml-1">
                            {{ $t('provisions.provision') }} {{ item.salary_amount }}
                    </span>
                    </v-col>
                    <v-col cols="12" :md="grid.md" class="font-weight-medium">
                        <span class="gold lighten-1 white--text px-3 rounded">{{ item.type }}</span>
                        <span class="tag-status white--text rounded px-3 ml-1" :class="state"> {{ item.paid }}</span>
                    </v-col>
                </v-row>

                <!--DATA-->
                <v-row justify="center" align="center" no-gutters>
                    <v-col cols="12">
                        <v-row align="center" no-gutters>
                            <v-icon small class="gold--text pr-1">mdi-account-tie</v-icon>
                            <span class="headline text-uppercase gold--text"
                                  style="font-size: 20px !important;">{{ item.seller }}</span>
                        </v-row>

                        <v-divider class="gold lighten-1"></v-divider>

                        <v-row align="center" no-gutters class="headline" style="font-size: 1.2em !important;">
                            <v-col v-bind="layout">
                                <v-row no-gutters align="center">
                                    <v-col cols="12" class="caption font-weight-bold">{{
                                            g_i18_keys('purchase_limit')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="gold--text">{{ item.purchase_limit }}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="layout">
                                <v-row no-gutters align="center">
                                    <v-col cols="12" class="caption font-weight-bold">{{
                                            g_i18_keys('agreement_limit')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="gold--text">{{ item.agreement_limit }}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="layout">
                                <v-row no-gutters align="center">
                                    <v-col cols="12" class="caption font-weight-bold">{{
                                            g_i18_keys('agreement_count')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="gold--text">{{ item.agreement_count }}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="layout">
                                <v-row no-gutters align="center">
                                    <v-col cols="12" class="caption font-weight-bold">{{ g_i18_keys('points') }}</v-col>
                                    <v-col cols="12" class="gold--text">{{ item.points }}</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>

                    <!--KEYS-->
                    <v-col v-if="false" cols="12">
                        <v-row justify="center">
                            <v-col v-for="v in keys" :key="v.value" xl="0" md="0" style="width: calc(100% / 11)">
                                <v-row no-gutters>
                                    <span class="caption gold--text">{{ g_i18_keys(v.value) }}</span>
                                </v-row>

                                <v-row no-gutters>
                                    <span class="font-weight-bold">{{ item[v.value] }}</span>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="false" cols="12">
                        <span class="caption info--text">ITEM_DATA: {{ item }}</span>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>
    </v-hover>
    <!--<v-hover v-slot:default="{hover}">
        <v-card
            class="card off mx-2 mb-1" :class="{ 'card_hover' : hover}"
            :elevation="hover ? 4 : 2" style="cursor: pointer;">
            <v-card-text class="card-state pa-1" :class="state">
                &lt;!&ndash;HEADER&ndash;&gt;
                <v-row no-gutters justify="center">
                    <v-col cols="12" class="pb-0" :md="grid.md">
                        <span class="font-weight-bold">
                            {{ item.date }} • <span class="gold&#45;&#45;text">{{ item.id }}</span>
                        </span>
                    </v-col>
                    <v-col cols="12" :md="grid.md" class="font-weight-bold">
                        <span class="green white&#45;&#45;text px-2 rounded">{{item.salary_amount}}</span>
                    </v-col>
                    <v-col cols="12">
                        <v-divider class="gold lighten-1"></v-divider>
                    </v-col>
                </v-row>

                &lt;!&ndash;DATA&ndash;&gt;
                <v-row justify="center" align="center" class="" no-gutters>
                    <v-row v-if="true" align="center" no-gutters class="headline" style="font-size: 1.2em !important;">
                        <v-col v-bind="layout" v-for="v in keys" :key="v.value">
                            <v-row no-gutters align="center">
                                <v-col cols="12" class="caption font-weight-bold">{{ g_i18_keys(v.value) }}</v-col>
                                <v-col cols="12" class="gold&#45;&#45;text">{{ g_isDefined(item[v.value]) }}</v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-row>

                &lt;!&ndash;FOOTER&ndash;&gt;
                <v-row v-if="false" justify="center" align="center" no-gutters>
                    <v-row no-gutters align="center" class="pa-0 ma-0 rounded">
                        <v-icon size="20" class="px-1">mdi-account-tie</v-icon>
                        <span class="caption">{{ item.seller }}</span>
                    </v-row>
                </v-row>
            </v-card-text>

        </v-card>
    </v-hover>-->
</template>

<script>
//import CardLayout from "@/components/layouts/CardLayout";

export default {
    name: "PackageProvisionCard",
    //components: {CardLayout},
    props: {item: Object, keys: undefined, state: undefined},
    data: () => ({
        grid: {sm: 6, md: 6, lg: 2},
        card: {cols: 6, xs: 6, sm: 3, md: 3, align: 'center', class: 'my-2 full-cols full-cols-5'}
    }),
    computed: {
        layout() {
            return this.card;
        }
    }
}
</script>